

import React, { useState } from "react";
import {
    Row, 
    Col,
    ModalHeader, 
    ModalBody,
    Button,
    Card,
    CardBody,
    Form,
    FormGroup,
    Input
} from "reactstrap";
import axios from "axios";
import '../../constants';

const Unsub = ( props ) => {

    const [emailVal, setEmailValue] = useState(props.qs && props.qs.email && props.qs.email !== "" ? props.qs.email : "");
    const [phoneVal, setPhoneValue] = useState('');
    const [showEmailSuccess, setShowEmailSuccess] = useState(false);
    const [showPhoneSuccess, setShowPhoneSuccess] = useState(false);
    const [showEmailError, setShowEmailError] = useState(false);
    const [showPhoneError, setShowPhoneError] = useState(false);

    const emailValidation = () => {
        const regex = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;

        if(emailVal !== "" && regex.test(emailVal) === false){
            setShowEmailError(true);
            setShowEmailSuccess(false);
            return false;
        }
        setShowEmailError(false);
        return true;
    }
    const phoneValidation = () => {
        const regex = /^((\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4})$/i;

        if(phoneVal !== "" && regex.test(phoneVal) === false){
            setShowPhoneError(true);
            setShowPhoneSuccess(false);
            return false;
        }
        setShowPhoneError(false);
        return true;
    }

    let query = {
        siteId : props.siteId,
        email : emailVal,
        phone : phoneVal
    }

    const handleSubmit = e => {
        e.preventDefault();
        if((emailVal !== "" && emailValidation()) && (phoneVal !== "" && phoneValidation()) || (emailVal !== "" && emailValidation()) || (phoneVal !== "" && phoneValidation())){
            var queryString = Object.keys(query).map(key => key + '=' + query[key]).join('&');
            axios.all([
                axios.get(global.API_URL + global.JOB_DIR + '/catchUnsub.jsp?' + queryString),
                axios.get(global.API_URL + '/search/unsubscribe.fsn?' + queryString),
            ])
            .then(data => {
                if(emailVal !== "" && emailValidation()){
                    setShowEmailSuccess(true);
                }
                if(phoneVal !== "" && phoneValidation()){
                    setShowPhoneSuccess(true);
                }
            }, (error) => {
                console.log('ERROR: '+error);
            });
        }
    }

    return (
        <React.Fragment>
            <ModalHeader style={{paddingBottom:0}}>
                <h3 className="center">Unsubscribe</h3>
            </ModalHeader>
            <ModalBody>
                <React.Fragment>
                    <div className="clearfix mainSection">
                        <div className="container">
                            <div className="clearfix contentWrapper">
                                <div className="mainContent" id="mainContentHolder">
                                    <div className="row">
                                        <div className="col-md-12 col-lg-12">
                                            <p>
                                            By entering  your e-mail address, you are opting to unsubscribe from
                                            {props.domain}'s e-mail lists. Adding your name to this list only
                                            removes you from all mail which {props.domain} deploys. Your e-mail will
                                            be compiled into a screening or suppression list along with all other
                                            collected e-mail opt-outs. By entering your e-mail, you will be
                                            unsubscribed from {props.domain} services and you authorize
                                            {props.domain} to provide this aggregate list to those third parties for
                                            the purpose of ensuring your e-mail is not utilized for e-mailing offers
                                            deployed by {props.domain}.
                                            </p><p>
                                            By entering your phone number, you are requesting to be added to {props.domain}'s 
                                            internal Do Not Call list. Adding your name to this list only
                                            removes you from all calls which {props.domain} deploys. The {props.domain}
                                            Do Not Call list has no relationship or affiliation with the Federal Do
                                            Not Call list. By adding yourself to this list, you will also be included
                                            on a {props.domain} suppression list that is distributed to third-party
                                            affiliates for them to add your phone number to their own internal Do Not
                                            Call list ONLY.</p>
                                            <p>
                                            It may take up to ten (10) business days to successfully remove your name
                                            from our lists.</p>
                                            <p>
                                            Please note: {props.domain} is not responsible for any third parties who
                                            do not promote our products or services.
                                            </p>
                                            <div style={{"text-align":"center"}}>
                                                <Card>
                                                    <CardBody>
                                                        <Form onSubmit={handleSubmit}>
                                                            {showEmailSuccess && <Row>
                                                                <Col>
                                                                    <p style={{"color":"rgb(0, 128, 0)"}}>Your email has been added to the supression list.</p>
                                                                </Col>
                                                            </Row>}
                                                            {showEmailError && <Row>
                                                                <Col>
                                                                    <p style={{"color":"#FF0000"}}>Please enter a valid email address</p>
                                                                </Col>
                                                            </Row>}
                                                            <Row>
                                                                <Col xs={{ "size": 8, offset: 2 }} >
                                                                    <FormGroup>
                                                                        <Input onChange={e => setEmailValue(e.target.value)} value={emailVal} type="email" name="email" maxLength="255" id="email" placeholder="Email"/>
                                                                    </FormGroup>
                                                                </Col>
                                                            </Row>
                                                            <Row>
                                                                <Col xs={{ "size": 8, offset: 2 }}>
                                                                    <br /><span><strong>-- And / Or --</strong></span><br /><br />
                                                                </Col>
                                                            </Row>
                                                            {showPhoneSuccess && <Row>
                                                                <Col>
                                                                    <p style={{"color":"rgb(0, 128, 0)"}}>Your phone number has been added to the supression list.</p>
                                                                </Col>
                                                            </Row>}
                                                            {showPhoneError && <Row>
                                                                <Col>
                                                                    <p style={{"color":"#FF0000"}}>Please enter a valid phone number</p>
                                                                </Col>
                                                            </Row>}
                                                            <Row>
                                                                <Col xs={{ "size": 8, offset: 2 }} >
                                                                    <FormGroup>
                                                                        <Input onChange={e => setPhoneValue(e.target.value)} type="text" name="phone" maxLength="14" id="phone" placeholder="Phone Number"/>
                                                                    </FormGroup>
                                                                </Col>
                                                            </Row>
                                                            <Row>
                                                                <Col xs={{ "size": 8, offset: 2 }} >
                                                                    <div className="form-actions">
                                                                        <Button type="submit" id="consumerPortalSubmit" className="btn btn-lg btn-rounded btn-dark-blue">Submit</Button>
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                        </Form>
                                                    </CardBody>
                                                </Card>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </React.Fragment>
            </ModalBody>
        </React.Fragment>
    )

}


export default Unsub;
